<template>
  <div id="app">
    <HeaderComponent />
    <router-view/>
    <notifications style="padding-top: 15px;" :max="3" position="top center" group="notify" />
  </div>
</template>

<script>

import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: 'App',
  components: { HeaderComponent }
}
</script>

<style>
body {
  font-family: 'Rubik', serif;
}

.vc-time .vc-select:last-child {
  display: none;
}

.vc-time > span {
  display: none;
}
</style>
