<template>
  <nav class="p-3 pl-8 header">
    <div class="flex justify-start items-center text-white mr-3">
      <div class="flex flex-row items-center w-full">
        <div class="flex items-center hidden xl:block">
          <img :src="logo">
        </div>
        <div class="ml-auto align-middle flex flex-row items-center flex-wrap">
          <div v-if="showActivePostmen && !showActive"
               class="postmen-fieldset mr-5 mb-2 lg:mb-0 flex flex-wrap justify-center align-middle">
            <div class="flex items-center">
              <span class="text-black mr-2">Pokaż dane historyczne:</span>
            </div>
            <button v-if="!showActive" class="text-black mr-3">
              <v-date-picker
                  @popoverDidHide="handleChooseDate"
                  class="date-picker"
                  v-model="date"
                  mode="dateTime"
                  :update-on-input="false"
                  is24hr
                  is-range
                  :max-date='new Date()'
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      class="rounded-xl bg-white text-black p-1 px-2 mr-3 mb-1 lg:mb-0 border-blue-400 border-2 transition duration-500 ease-in-out"
                      placeholder="Od"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                  />
                  <input
                      class="rounded-xl bg-white text-black p-1 px-2 mr-3 mb-1 lg:mb-0 border-blue-400 border-2 transition duration-500 ease-in-out"
                      placeholder="Do"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                  />
                </template>
              </v-date-picker>
              <button class="font-bold" @click="clearDate">&times;</button>
            </button>
            <button
                v-if="showActivePostmen && !showActive"
                class="rounded-xl bg-white text-black p-1 px-2 border-blue-400 border-2 hover:bg-blue-100 transition duration-500 ease-in-out"
                @click="setShowActivePostmenListModal(true)"
                :disabled="numberOfActivePostmen === 0"
            >
              Aktywni listonosze: <b>{{ numberOfActivePostmen }}</b>
            </button>
          </div>
          <span
              v-if="showActive"
              class="mr-5 rounded-xl border-blue-500 border-2 bg-white p-2 text-black text-center cursor-pointer"
              style="width: 190px;"
              @click="handleClickShowShiftPointsTable"
          >
            {{ showShiftPointsTable ? 'Ukryj tabelę lokalizacji' : 'Pokaż tabelę lokalizacji' }}
          </span>
          <span
              v-if="showActive"
              class="mr-5 rounded-xl border-green-500 border-2 bg-white p-2 text-black text-center cursor-pointer"
              style="width: 165px;"
              @click="handleClickPlay"
          >
            <b>{{ this.playLinesStatus === 'play' || this.playLinesStatus === 'pause' ? 'Zakończ animację' : 'Animuj trasę' }}</b>
          </span>
          <div v-if="this.playLinesStatus === 'play' || this.playLinesStatus === 'pause'" class="mt-1">
              <span
                  class="mr-3 border-green-500 border-2 bg-white p-2 cursor-pointer text-black text-center play-buttons"
                  @click="handleClickPlayAction('prev')"
              >
              <font-awesome-icon
                  size="lg"
                  icon="arrow-left"
              />
          </span>
            <span
                class="mr-3 border-green-500 border-2 bg-white p-2 cursor-pointer text-black text-center play-buttons"
                @click="handleClickPlayAction('pause')"
            >
              <font-awesome-icon
                  size="lg"
                  :icon="this.playLinesStatus === 'play' ? 'pause' : 'play'"
              />
          </span>
            <span
                class="mr-5 border-green-500 border-2 bg-white p-2 cursor-pointer text-black text-center play-buttons"
                @click="handleClickPlayAction('next')"
            >
              <font-awesome-icon
                  size="lg"
                  icon="arrow-right"
              />
          </span>
          </div>
          <span
              v-if="showTimer && ((!showActive && showActivePostmen) || showActive) && !dateTimeForActivePostmen.start"
              class="mr-5 rounded-xl border-red-500 border-2 bg-white p-2 text-black text-center"
              style="width: 75px;"
          >
            AR: <b>{{ autoRefreshTime }}</b>
          </span>
          <font-awesome-icon
              size="2x"
              class="cursor-pointer settings-button ml-auto"
              icon="cog"
              @click="setShowSettingsModal(true)"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  SET_AUTO_REFRESH,
  SET_PLAY_LINES_STATUS, SET_PLAY_LINES_STEP,
  SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL,
  SET_SHOW_SETTINGS_MODAL, SET_SHOW_SHIFT_POINTS_TABLE
} from "@/store/modules/map/mutations";
import logo from '@/assets/big-logo.png';
import {createNamespacedHelpers} from "vuex";
import {CHANGE_DATE_TIME_FOR_ACTIVE_POSTMEN, CHANGE_PLAY_LINES_STEP} from "@/store/modules/map/actions";

const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('map');

export default {
  name: "Header",
  data: () => ({
    logo: logo,
    date: {
      start: null,
      end: null
    }
  }),
  computed: {
    ...mapGetters([
      'numberOfActivePostmen',
    ]),
    ...mapState({
      showActivePostmen: state => state.showActivePostmen,
      autoRefresh: state => state.autoRefresh,
      autoRefreshTime: state => state.autoRefreshTime,
      dateTimeForActivePostmen: state => state.dateTimeForActivePostmen,
      showShiftLines: state => state.showShiftLines,
      playLinesStatus: state => state.playLinesStatus,
      playLinesStep: state => state.playLinesStep,
      showShiftPointsTable: state => state.showShiftPointsTable,
    }),
    showActive() {
      const {date, key} = this.$route.query;
      return !!(date && key);
    },
    showTimer() {
      const {refresh} = this.$route.query;
      if (!refresh) {
        return !!(this.autoRefresh);
      } else {
        return JSON.parse(refresh);
      }
    }
  },
  methods: {
    ...mapMutations({
      setShowSettingsModal: SET_SHOW_SETTINGS_MODAL,
      setShowActivePostmenListModal: SET_SHOW_ACTIVE_POSTMEN_LIST_MODAL,
      setPlayLinesStatus: SET_PLAY_LINES_STATUS,
      setPlayLinesStep: SET_PLAY_LINES_STEP,
      setAutoRefresh: SET_AUTO_REFRESH,
      setShowShiftPointsTable: SET_SHOW_SHIFT_POINTS_TABLE
    }),
    ...mapActions({
      changeDateTime: CHANGE_DATE_TIME_FOR_ACTIVE_POSTMEN,
      changePlayLinesStep: CHANGE_PLAY_LINES_STEP
    }),
    handleChooseDate() {
      if (this.date.start && this.date.end) {
        this.changeDateTime(this.date);
      }
    },
    clearDate() {
      this.date = {
        start: null,
        end: null
      };
      this.changeDateTime(null);
    },
    handleClickPlay() {
      this.setShowShiftPointsTable(false);
      if (this.playLinesStatus === 'play' || this.playLinesStatus === 'pause') {
        this.setPlayLinesStatus('stop');
        this.setPlayLinesStep(0);
        this.setAutoRefresh(true);
      } else {
        this.setPlayLinesStatus('play');
        this.setAutoRefresh(false);
      }
    },
    handleClickPlayAction(action) {
      if (action === 'pause') {
        this.setPlayLinesStatus(this.playLinesStatus === 'pause' ? 'play' : 'pause');
      } else {
        this.setPlayLinesStatus('pause');
        this.changePlayLinesStep(this.playLinesStep + (action === 'next' ? 1 : -1));
      }
    },
    handleClickShowShiftPointsTable() {
      this.setPlayLinesStatus('stop');
      this.setPlayLinesStep(0);
      this.setAutoRefresh(true);
      this.setShowShiftPointsTable(!this.showShiftPointsTable);
    },
  },
}
</script>

<style scoped>
.header {
  position: relative;
  z-index: 2;
  min-height: 80px;
  background-color: #DF8D36;
  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.4);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.4);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.4);
}

* {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.postmen-fieldset {
  padding: 8px 10px;
  border: 1px solid #1e1ef8;
  background-color: white;
  border-radius: 15px;
}

img {
  height: 60px;
  width: auto;
  margin-right: 15px;
}

.settings-button {
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -o-transition: 0.70s;
}

.settings-button:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: black;
}

.play-buttons {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

</style>