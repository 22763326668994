import { parse } from 'wkt';

export const geoJsonFromWKT = (wktArray) => {
    const arrayOfFeatures = wktArray.map((polygon) => {
            return ({
                type: "Feature",
                geometry: parse(polygon['geometry']),
                properties: {
                    color: polygon.color || '#606060',
                    jpt_code: polygon.jpt_code,
                    id: polygon.id,
                    name: polygon.name,
                    layer: polygon.layer,
                    coverage: polygon.coverage,
                    parent: polygon.parent,
                },
            })
    })

    return ({type: "FeatureCollection", features: arrayOfFeatures});
};

export const getColorForPercentage = (percentage) => {
    const percent = percentage / 100;

    let red = 255;
    let green = 255;
    if (percent >= 0 && percent <= 0.5) {
        green = 510 * percent;
    } else if (percent > 0.5 && percent <= 1) {
        red = -510 * percent + 510;
    }

    return 'rgb(' + [red, green, 0].join(',') + ')';
}