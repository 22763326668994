import { axios as axiosInstance } from "@/services/axiosInstance";

// Map
export const getPolygons = async ({ southWest, northEast, zoom, forceLevel, cancelToken }) =>
  axiosInstance.get(`/map/polygon?southWestPoint=${[southWest.lat(), southWest.lng()]}&northEastPoint=${[northEast.lat(), northEast.lng()]}&zoom=${zoom}&forceLevel=${forceLevel}`, {
    cancelToken: cancelToken
  }).catch(function (thrown) {
      throw new Error(thrown);
  });

export const getActivePostmen = ({ start, end }) => axiosInstance.get(`/map/active${start ? '?start='+start : ''}${end ? '&end='+end : ''}`);
export const getShiftPostman = ({ key, date, shiftId, newFormat }) =>
  axiosInstance.get(`/map/postman?key=${key}&date=${date}${shiftId ? '&shiftId='+shiftId : ''}${newFormat ? '&newFormat='+newFormat : ''}`);
